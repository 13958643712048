import React from "react";
import { StaticWrapper, ContentWrapper, Bold, ContentParagraph, PromoLink } from "./promotionsHelpers";

const ManualPromotion = () => {
  return (
    <StaticWrapper $color="#d6c7bc">
      <ContentWrapper >
        <ContentParagraph color="#000">
          <Bold>📢 ZAKOŃCZENIE LATA 📢</Bold> -15% NA KOSMETYKI W KATEGORII <PromoLink to="sklep/kosmetyki-naturalne-lato/">LATO</PromoLink>
        </ContentParagraph>
      </ContentWrapper>
    </StaticWrapper>
  );
};

export default ManualPromotion;
