import styled from "styled-components";
import { theme } from "../theme/theme";

export const ProductCategoryPage = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-content: space-evenly;
  grid-template-areas: "product ";
  grid-gap: ${theme.spaces.margin.medium};

  @media (min-width: 1000px) {
    grid-template-columns: 0.2fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "sidebar product";
  }
  @media (min-width: 1150px) {
    margin: 0 ${theme.spaces.margin.horizontal_small};
  }

  nav {
    display: none;

    @media (min-width: 1000px) {
      display: block;
      position: relative;
      grid-area: sidebar;
    }
  }

  .product-list {
    grid-area: product;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${theme.spaces.margin.big};
    justify-items: center;

    @media (min-width: 650px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: ${theme.spaces.margin.big};
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: ${theme.spaces.margin.xxl};
    }
  }
`;
