import { getWindow } from "../types/window";

const isBrowser = () => typeof window !== "undefined";

export const trimPolishPrefix = (phoneNumber: string): string => {
  const polishPrefix: string = "+48";
  if (phoneNumber.startsWith(polishPrefix)) {
    return phoneNumber.substring(polishPrefix.length);
  } else {
    return phoneNumber;
  }
};

export const clearIntervals = () => {
  if (isBrowser() && getWindow().intervalToClear && getWindow().intervalToClear?.id && getWindow().intervalToClear?.location === window.location.href) {
    try {
      clearInterval(getWindow().intervalToClear?.id as NodeJS.Timeout);
      getWindow().intervalToClear = undefined;
    } catch (e) {
      //
    }
  }
};
