import { InPostPoint } from "@on/model-store";
import { OrlenWidget, OrlenWidgetClass } from "../../components/checkout/helpers/checkoutShippingOrlenGeoWidgetTypes";

export type DataLayerEvent = string;

export type DataLayerECommerce = {
  items: {
    item_name: string;
    item_id: string;
    currency: "PLN";
    price: number;
    discount: number;
    quantity: number;
  }[];
};

type DataLayer =
  | {
      ecommerce: null;
    }
  | {
      event: DataLayerEvent;
      currency: "PLN";
      value: number;
      ecommerce: DataLayerECommerce;
    }
  | {
      event: DataLayerEvent;
      currency: "PLN";
      item_list_name: string;
      ecommerce: DataLayerECommerce;
    }
  | {
      event: DataLayerEvent;
      value: number;
      currency: "PLN";
      transaction_id?: string;
      user?: string; //email
      tax: string;
      coupon?: string;
      num_items?: number;
      ecommerce: DataLayerECommerce;
    };

export type WindowAccess = Window &
  typeof globalThis & {
    intervalToClear?: { id: NodeJS.Timeout; location: string };
    blikTimer?: number;
    messageDisplayed?: string;
    yotpoLoaded?: boolean;
    yotpo?: {
      refreshWidgets: () => void;
    };
    eventstream: any;
    dataLayer: DataLayer[];
    Tracking: any;
    Common: any;
    loadingMethod: string;
    geoWidgetCallback: (point: InPostPoint) => void;
    geoWidgetCallbackInternal: (point: InPostPoint) => void;
    geoOrlenWidget: OrlenWidget;
    RuchWidget: OrlenWidgetClass;
    googleLoginButtonsCount?: number;
    googleLoginButtonCallback: () => void;
    google: {
      accounts: {
        id: {
          initialize: (props: { client_id: string; ux_mode: string; callback: (response: { credential: string }) => void }) => void;
          renderButton: (element: HTMLElement, options: { type: string; width: string }) => void;
        };
      };
    };
  };

export const getWindow = (): WindowAccess => window as WindowAccess;
