import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { getImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image";
import useWindowSize from "../../../utils/hooks/useWindowSize";
import { TitleHomepage, ButtonGlobal, BgImage, BgImageText } from "../../UI/index";
import { theme } from "../../../styles/theme/theme";

const Wrapper = styled.section`
  margin: ${theme.spaces.margin.vertical} 0;
  position: relative;
  display: grid;

  @media (min-width: 680px) {
    margin: ${theme.spaces.margin.vertical_double} 0;
  }

  @media (min-width: 800px) {
    grid-template-columns: repeat(2, 1fr);

    &:after,
    &:before {
      content: "";
      width: 50%;
      position: absolute;
      bottom: 0;
      height: 1px;
      opacity: 0.9;
      color: ${theme.colors.secondary};
      border: 1px solid ${theme.colors.secondary_opacity};
    }

    &:before {
      top: 0;
    }
  }
`;

/* const StyledBackgroundImage = styled(BgImageLocal)<{ children: React.ReactNode; tag: string }>`
  height: 100%;
`; */

const Text = styled.article<{ $white?: boolean }>`
  padding: ${theme.spaces.padding.big} 0;
  padding-right: ${theme.spaces.padding.large};
  padding-left: ${theme.spaces.padding.small};
  font-size: ${theme.fonts.fontSize.xl};
  margin: ${theme.spaces.margin.large} 0;
  color: ${({ $white }) => ($white ? "#fff" : "#000")};

  @media (min-width: 800px) {
    align-self: center;
  }

  @media (min-width: 1200px) {
    padding-left: 30%;
  }

  p {
    margin-bottom: ${theme.spaces.margin.big};
    color: inherit;
    margin-top: -3em;
    line-height: 1.8;

    @media (min-width: 800px) {
      font-weight: ${theme.fonts.fontWeight.thin};
      line-height: 2;
    }

    @media (min-width: 1200px) {
      padding-right: 7em;
    }
  }
`;

interface DataProps {
  file: IGatsbyImageData;
}

const query = graphql`
  {
    file(relativePath: { eq: "o_nas.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, width: 900)
      }
    }
  }
`;

const About = () => {
  const { file } = useStaticQuery<DataProps>(query);

  const image = getImage(file);
  if (!image) {
    throw new Error("Image not found");
  }

  const { width = 0 } = useWindowSize();

  const mobile = width < 800;

  const AboutInner = () => (
    <Text $white={mobile && true}>
      <TitleHomepage title="o nas" span="poznaj naszą historię" place="start" color={mobile ? "fff" : "inherit"} />

      <p>
        Opcja Natura to polski producent kosmetyków naturalnych. Marka została stworzona przez Agatę Suligę, pasjonatkę Natury, z wykształcenia magister biologii. Nasze kosmetyki są proste, naturalne
        i skuteczne. W procesie produkcji stosujemy wyciągi z ziół, naturalne olejki eteryczne, oleje z owoców, kwiatów i wszystko to, czym Natura nas obdarowała. Myj siebie i rodzinę odżywczymi
        kosmetykami, nie chemią!
      </p>
      <ButtonGlobal text="czytaj więcej" link="/o-nas" />
    </Text>
  );

  return (
    <Wrapper>
      {width < 800 ? (
        <>
          <BgImage $tag="div" image={image} $height="100%" />
          <BgImageText textOverlay={true}>
            <AboutInner />
          </BgImageText>
        </>
      ) : (
        <>
          <AboutInner />
          <StaticImage src="../../../assets/images/o_nas.jpg" layout="constrained" alt="historia marki Opcja Natura" />
        </>
      )}
    </Wrapper>
  );
};

export default About;
