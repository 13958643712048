import React, { useEffect } from "react";
import "../../styles/stylesheets/cookieconsentmod.css";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

const CookieConstentMessage = () => {
  useEffect(() => {
    void CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: "cloud",
          position: "bottom center",
          equalWeightButtons: false,
          flipButtons: false,
        },
        preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: true,
          flipButtons: false,
        },
      },
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        analytics: {},
      },

      language: {
        default: "en",
        translations: {
          en: {
            consentModal: {
              title: "Używamy plików cookie",
              description:
                "Używamy plików cookie i innych technologii śledzenia, aby poprawić jakość przeglądania naszej witryny, wyświetlać spersonalizowane treści i reklamy, analizować ruch w naszej witrynie i wiedzieć, skąd pochodzą nasi użytkownicy.",
              acceptAllBtn: "Zgoda",
              acceptNecessaryBtn: "Odmawiam",
              showPreferencesBtn: "Zmiana ustawień",
              footer: '<a href="/polityka-prywatnosci/">Polityka prywatności</a>\n<a href="/regulamin/">Regulamin sklepu</a>',
            },
            preferencesModal: {
              title: "Centrum ustawień cookie",
              acceptAllBtn: "Zgoda",
              acceptNecessaryBtn: "Odmawiam",
              savePreferencesBtn: "Zapisz ustawienia",
              closeIconLabel: "Zamknij",
              sections: [
                {
                  title: "Twoja prywatność jest dla nas ważna\n",
                  description:
                    "Pliki cookie to bardzo małe pliki tekstowe, które są tworzone i przechowywane na komputerze użytkownika podczas odwiedzania strony internetowej. Używamy plików cookie do różnych celów, w tym do ulepszania obsługi online na naszej stronie internetowej (na przykład, aby zapamiętać dane logowania do konta).\n" +
                    "\n" +
                    "Możesz zmienić swoje ustawienia i odrzucić niektóre rodzaje plików cookie, które mają być przechowywane na twoim komputerze podczas przeglądania naszej strony. Możesz również usunąć wszystkie pliki cookie już zapisane na komputerze, ale pamiętaj, że usunięcie plików cookie może uniemożliwić korzystanie z części naszej strony internetowej.",
                },
                {
                  title: "Niezbędne",
                  description:
                    "Te pliki cookie są niezbędne do świadczenia usług dostępnych za pośrednictwem naszej strony internetowej i umożliwienia korzystania z niektórych funkcji naszej strony internetowej.\n" +
                    "\n" +
                    "Bez tych plików cookie nie możemy zapewnić usług na naszej stronie internetowej.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Śledzenie i wydajność",
                  description:
                    "Te pliki cookie służą do zbierania informacji w celu analizy ruchu na naszej stronie internetowej i sposobu, w jaki użytkownicy korzystają z naszej strony internetowej.\n" +
                    "\n" +
                    "Na przykład te pliki cookie mogą śledzić takie rzeczy, jak czas spędzony na stronie lub odwiedzane strony, co pomaga nam zrozumieć, w jaki sposób możemy ulepszyć naszą witrynę internetową.\n" +
                    "\n" +
                    "Informacje zebrane przez te pliki nie identyfikują żadnego konkretnego użytkownika.",
                  linkedCategory: "analytics",
                },
                {
                  title: "Więcej informacji",
                  description:
                    "W przypadku jakichkolwiek pytań dotyczących naszej polityki dotyczącej plików cookie i Twoich wyborów, skontaktuj się z nami.\n" +
                    "\n" +
                    'Aby dowiedzieć się więcej, odwiedź naszą  <a href="/polityka-prywatnosci/">Politykę poprawności</a>',
                },
              ],
            },
          },
        },
      },
    });
    document.documentElement.classList.add("cc--light-funky");
  }, []);
  return <></>;
};

export default CookieConstentMessage;
