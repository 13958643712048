import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "../../UI/elements/Link";
import { CategoriesProps, ValidateSubType } from "../../../utils/types/graphQLTypes";

const query = graphql`
  {
    allStoreCategory(filter: { active: { eq: true } }, sort: { sort: ASC }) {
      nodes {
        id
        slug
        name
      }
    }
  }
`;

type CategoriesPropsLocal = {
  allStoreCategory: {
    nodes: {
      id: string;
      name: string;
      slug: string;
    }[];
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TYPE_GUARD2: ValidateSubType<CategoriesPropsLocal, CategoriesProps> = true;

type DataProps = CategoriesPropsLocal;

const NavbarCategories = () => {
  const {
    allStoreCategory: { nodes: categories },
  } = useStaticQuery<DataProps>(query);

  return (
    <>
      {categories.map((category) => {
        return (
          <li key={category.id}>
            <Link to={`/sklep/${category.slug}`} activeClassName="activeMobile">
              {category.name}
            </Link>
          </li>
        );
      })}
    </>
  );
};

export default NavbarCategories;
