import React from "react";
import styled from "styled-components";
import Tabs from "./Tabs";
import Description from "./Description";
import Price from "./Price";
import Gallery from "./gallery";
import BuyButton from "../buyButton/BuyButton";
import Title from "./Title";
import Reviews from "./Reviews";
import { ProductProps } from "../../../utils/types/graphQLTypes";
import { theme } from "../../../styles/theme/theme";
import { Currency } from "@on/model-store";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spaces.margin.vertical};

  @media (min-width: 850px) {
    position: relative;
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-gap: ${theme.spaces.margin.base};
    margin: 0 ${theme.spaces.margin.xxl};
  }
`;

const ProductBuy = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  padding: 0 5%;
  margin-bottom: ${theme.spaces.margin.xxl};
  margin-top: ${theme.spaces.margin.xxl};

  @media (min-width: 1010px) {
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
    margin-top: unset;
    gap: 1.2rem;
  }
`;

const TabSection = styled.section`
  position: sticky;
  top: 40px;
  padding: 0 ${theme.spaces.padding.base};

  @media (min-width: 980px) {
    padding: 0;
  }
  @media (min-width: 1200px) {
    max-width: 80%;
  }
`;

interface Props {
  product: Pick<ProductProps, "details" | "images" | "name" | "name_span" | "custom_attributes" | "description" | "product_id" | "ean" | "slug" | "label"> & {
    prices: Record<
      Currency,
      {
        active: boolean;
        currency: string;
        unit_price: number;
        previous_unit_price?: number;
        sale: boolean;
        leastPriceLast30Days: number;
      }
    >;
  };
  siteUrl: string;
  productImageUrl: string;
}

const Index = ({ product, siteUrl, productImageUrl }: Props) => {
  const productTabs = [];

  if (product.custom_attributes.dlaczego_dziala) {
    productTabs.push({ text: product.custom_attributes.dlaczego_dziala, button: "jak działa" });
  }
  if (product.custom_attributes.sklad) {
    productTabs.push({ text: product.custom_attributes.sklad, button: "skład" });
  }
  if (product.details) {
    productTabs.push({ text: product.details, button: "szczegóły" });
  }

  const price = product.prices["PLN"];
  return (
    <Wrapper>
      <Gallery images={product.images} label={product.label} imageAlt={product.name} />
      <section>
        <Title productTitle={product.name} productTitleSpan={product.name_span} />
        <ProductBuy>
          <Price
            price={price.sale && price.previous_unit_price ? price.previous_unit_price : price.unit_price}
            sale={price.sale}
            sale_price={price.sale ? price.unit_price : undefined}
            least_price={price.previous_unit_price}
            capacity={product.custom_attributes.capacity}
            productName={product.name}
            isProductPage={true}
            margin="0"
          />

          <BuyButton productId={product.product_id} images={product.images} />
        </ProductBuy>
        <Description text={product.description} />
        {productTabs.length > 0 && (
          <TabSection>
            <Tabs tabs={productTabs} />
            <Reviews
              price={price.sale && price.previous_unit_price ? price.previous_unit_price : price.unit_price}
              slug={product.slug}
              name={product.name}
              productEan={product.ean}
              siteUrl={siteUrl}
              productImageUrl={productImageUrl}
            />
          </TabSection>
        )}
      </section>
    </Wrapper>
  );
};

export default Index;
