import React, { useState, useRef, useEffect, MutableRefObject } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ProductText } from "../UI/index";
import Price from "../shop/product/Price";
import BuyButton from "../shop/buyButton/BuyButton";
import { BsArrowLeft as Close } from "react-icons/bs";

import { pulseAnimation } from "../../styles/mixins";
import { theme } from "../../styles/theme/theme";
import Link from "../UI/elements/Link";
import { ProductProps } from "../../utils/types/graphQLTypes";

const Circle = styled.div<{
  open: boolean;
  order: number;
  $circlePosition: {
    elementOne: string;
    elementTwo: string;
    elementThree: string;
    elementFour: string;
/*     elementFive: string; */
  };
}>`
  position: absolute;
  cursor: pointer;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: ${pulseAnimation} 2s infinite;
  transform: ${(props) => (props.open ? "scale(1.5)" : "scale(1)")};
  transition: all 0.5s ease-in-out;
  z-index: 2;
  ${({ order, $circlePosition }) =>
    order === 0 &&
    css`
      ${$circlePosition.elementOne}
    `};
  ${({ order, $circlePosition }) =>
    order === 1 &&
    css`
      ${$circlePosition.elementTwo}
    `};
  ${({ order, $circlePosition }) =>
    order === 2 &&
    css`
      ${$circlePosition.elementThree}
    `};
    ${({ order, $circlePosition }) =>
    order === 3 &&
    css`
      ${$circlePosition.elementFour}
    `};
  @media (min-width: 800px) {
    height: 25px;
    width: 25px;
  }
  .hover {
    transform: scale(3);
  }
`;

const StyledClose = styled(Close)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -10px;
  height: 20px;
  width: 30px;
  color: ${theme.colors.secondary};

  @media (min-width: 1200px) {
    width: 35px;
  }
`;

const Modal = styled.div<{ open: boolean; ref: MutableRefObject<HTMLDivElement> }>`
  position: absolute;
  display: grid;
  grid-template-rows: auto auto auto;
  align-content: space-between;
  padding: ${theme.spaces.padding.base};
  z-index: 5;
  height: 100%;
  width: 70%;
  top: 0;
  transform: ${(props) => (props.open ? "translateX(0%)" : "translateX(-100vw)")};
  transition-duration: 1s;
  transition-timing-function: forwards;
  background-color: #fff;
  border: 1px solid ${theme.colors.secondary_opacity};
  @media (min-width: 700px) {
    max-width: 35%;
  }
  @media (min-width: 1100px) {
    max-width: 40%;
  }
`;

const Title = styled.div`
  position: relative;
  text-transform: uppercase;

  h3 {
    letter-spacing: 3px;
    font-size: ${theme.fonts.fontSize.xl};
    margin-bottom: 0.5em;
    margin-right: 40px;
  }
  .price-text {
    font-weight: ${theme.fonts.fontWeight.light};
    color: ${theme.colors.secondary};
    opacity: 0.8;
  }
`;

const Description = styled.article`
  display: none;
  text-align: justify;
  text-justify: inter-word;

  ul {
    display: none;
  }

  @media (min-width: 500px) {
    display: block;
    padding: ${theme.spaces.padding.small} 0;
  }

  @media (min-width: 700px) {
    display: none;
  }

  @media (min-width: 1100px) {
    display: block;
  }
`;

const StyledImage = styled(GatsbyImage)`
  max-width: 60%;
  margin: 0.8em 0;
  align-self: center;

  @media (min-width: 700px) {
    display: block;
    max-width: 50%;
  }

  @media (min-width: 1100px) {
    display: block;
    max-width: 60%;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  p {
    margin: 1em auto;
    line-height: 1.4;

    @media (min-width: 1100px) {
      line-height: 1.8;
    }
  }

  .margin {
    margin-top: ${theme.spaces.margin.medium};
    @media (min-width: 1200px) {
      margin-top: ${theme.spaces.margin.large};
    }
  }
`;

const ModalPriceWrapper = styled(Price)`
  margin: 0.3em 0;
  justify-content: flex-start;
`;

interface ProductModalProps {
  product: Pick<ProductProps, "product_id" | "slug" | "description" | "prices" | "custom_attributes" | "name_span" | "images" | "name">;
  order: number;
  circlePosition: {
    elementOne: string;
    elementTwo: string;
    elementThree: string;
    elementFour: string;
    /* elementFive: string; */
  };
}

const ProductModal = ({ product, order, circlePosition }: ProductModalProps) => {
  const [showModal, setShowModal] = useState(false);
  const node: MutableRefObject<HTMLDivElement> | MutableRefObject<undefined> = useRef();

  const getLastImage = product.images.length - 1;
  const productImage = getImage(product.images[getLastImage].fileLocal);
  if (!productImage) {
    throw new Error("No image found");
  }

  const MAX_LENGHT = 200;

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent | TouchEvent) => {
      if (node.current?.contains(e.target as Node)) {
        return;
      }
      // outside click
      setShowModal(!showModal);
    };

    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [showModal]);

  const price = product.prices["PLN"];

  return (
    <>
      <Circle order={order} onClick={() => setShowModal(!showModal)} open={showModal} $circlePosition={circlePosition} />
      <Modal open={showModal} ref={node as MutableRefObject<HTMLDivElement>}>
        <Title>
          <StyledClose role="button" aria-label="close" onClick={() => setShowModal(false)} />
          <h3> {product.name}</h3>
          <span className="price-text" dangerouslySetInnerHTML={{ __html: product.name_span }} />
          <div>
            <ModalPriceWrapper
              price={price.sale && price.previous_unit_price ? price.previous_unit_price : price.unit_price}
              sale={price.sale}
              sale_price={price.sale ? price.unit_price : undefined}
              capacity={product.custom_attributes.capacity}
              isProductPage={false}
              productName={product.name}
            />
          </div>
        </Title>

        <StyledImage image={productImage} alt={`naturalny kosmetyk ${product.name}`} />

        <Text>
          <Description>
            {product.description.length > MAX_LENGHT ? <ProductText text={product.description.substring(0, MAX_LENGHT).concat("...")} /> : <ProductText text={product.description} />}
          </Description>

          <BuyButton productId={product.product_id} images={product.images} />

          <Link to={`/sklep/${product.slug}`} className="border__out margin">
            Zobacz szczegóły
          </Link>
        </Text>
      </Modal>
    </>
  );
};

export default ProductModal;
