import * as storeService from "../service/storeService";
import { Action, CartState } from "../../utils/types/states";
import { Cart } from "../../utils/types/dto";

export const GIFT_PACKING_SLUG = "gift-packing";

export const getGiftPackingItem = (cart: Cart): Cart["items"][0] | undefined => {
  return cart.items.find((item) => item.product.custom_attributes.giftPacking);
};

export const getProductOptions = (giftPackingItem: CartState["items"][0]): { original_line_id: string; productName: string; quantity: number }[] => {
  return JSON.parse(giftPackingItem.options[0].value) as { original_line_id: string; productName: string; quantity: number }[];
};

function isGiftPackingItemBeingRemoved(giftPackingItem: { id: string }, lineId: string) {
  return giftPackingItem.id === lineId;
}

export const adjustGiftPackingQuantity = async (cart: Cart, lineId: string, quantity: number): Promise<{ cart: Cart; cartModified: boolean }> => {
  const giftPackingItem = getGiftPackingItem(cart);
  let cartModified = false;
  if (giftPackingItem && !isGiftPackingItemBeingRemoved(giftPackingItem, lineId)) {
    let productOptions = getProductOptions(giftPackingItem);
    if (quantity === 0) {
      productOptions = productOptions.filter((productOption) => productOption.original_line_id !== lineId);
    }
    productOptions.filter((productOption) => productOption.original_line_id === lineId).forEach((productOption) => (productOption.quantity = quantity));

    if (productOptions.some((productOption) => productOption.quantity > 0)) {
      cart = await storeService.updateItem({ product_id: giftPackingItem.product.product_id }, 1, false, [{ id: "Products", value: JSON.stringify(productOptions) }]);
    } else {
      cart = await storeService.removeItems(giftPackingItem.product.product_id);
    }
    cartModified = true;
  }
  return { cart, cartModified };
};

const buildOption = (line: Cart["items"][0], quantity: number) => {
  return {
    product_id: line.product.product_id,
    image: line.product.images[0].file.url,
    productName: line.product.name,
    original_line_id: line.id,
    quantity,
  };
};

export const packItemAsGift = async (line: Cart["items"][0], quantity: number, dispatch: React.Dispatch<Action>, cart: Cart) => {
  if (!cart) {
    cart = await storeService.getCart();
  }
  const giftPackingItem = getGiftPackingItem(cart);
  if (giftPackingItem) {
    const productOptions = getProductOptions(giftPackingItem);
    const productOption = productOptions.find((productOption) => productOption.original_line_id === line.id);
    if (productOption) {
      productOption.quantity = quantity;
    } else {
      productOptions.push(buildOption(line, quantity));
    }
    cart = await storeService.updateItem({ product_id: giftPackingItem.product.product_id }, giftPackingItem.quantity, true, [{ id: "Products", value: JSON.stringify(productOptions) }]);
  } else {
    cart = await storeService.addToCart({ product_slug: GIFT_PACKING_SLUG }, 1, [{ id: "Products", value: JSON.stringify([buildOption(line, quantity)]) }]);
  }
  dispatch({ type: "PACKED_AS_GIFT", payload: cart as unknown as Record<string, unknown> });
};

export const isInGiftPackage = (cart: Cart, line: Cart["items"][0]) => {
  const giftPackingItem = getGiftPackingItem(cart);
  if (giftPackingItem) {
    return getProductOptions(giftPackingItem).some((productOption) => productOption.original_line_id === line.id);
  }
  return false;
};

export const getMaxAllowedCountity = (cart: Cart, original_line_id: string): number => {
  return (
    cart.items
      .filter((line) => line.id === original_line_id)
      .map((line) => line.quantity)
      .find(Boolean) ?? 0
  );
};
