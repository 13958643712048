import React from "react";
import styled from "styled-components";
import { IconCart, IconUser, IconLogout } from "../UI";

const Wrapper = styled.div`
  align-self: center;
  justify-self: end;
  display: grid;
  grid-auto-flow: column;
  justify-items: end;
  column-gap: 1.5em;

  @media (min-width: 600px) {
    column-gap: 2.5em;
  }
`;

interface Props {
  width: number;
  path: string;
}

const NavbarIcons = ({ width, path }: Props) => {
  const hideBasket = path === "/order-confirmation" || path === "/order-confirmation/";
  return (
    <Wrapper data-testid={"navbar-header__iconsWrapper"}>
      {width > 450 && <IconLogout />}
      {width > 450 && <IconUser />}
      {!hideBasket && <IconCart />}
    </Wrapper>
  );
};

export default NavbarIcons;
