import React from "react";
import styled from "styled-components";
import { ButtonGlobal } from "../../UI";
import { theme } from "../../../styles/theme/theme";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike, withArtDirection } from "gatsby-plugin-image";

const Wrapper = styled.header`
  justify-self: center;
  display: grid;
`;

const StyledBanner = styled(GatsbyImage)<{ $height: string; $tag: string }>`
  height: 80vh;
  grid-area: 1/1;
  position: relative;
  margin-top: -9em;
  display: grid;

  @media (min-width: 900px) {
    height: 95vh;
  }

  @media (min-width: 900px) {
    margin-top: -10em;
  }
`;

const BannerText = styled.div`
  margin-top: 20%;
  grid-area: 1/1;
  position: relative;
  color: #fff;
  justify-self: center;

  @media (min-width: 600px) {
    margin-top: 25%;
  }

  @media (min-width: 830px) {
    margin-top: 12%;
  }

  @media (min-width: 1100px) {
    margin-top: 10%;
  }

  h1,
  h5 {
    text-transform: uppercase;
    text-align: center;
    color: ${theme.colors.white};
    padding-bottom: ${theme.spaces.padding.base};
  }

  h1 {
    letter-spacing: 5px;
  }

  .link_wrapper {
    display: grid;
    justify-items: center;
  }
`;

const query = graphql`
  {
    desktopImage: file(relativePath: { eq: "hero/opcja-natura_hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90, breakpoints: [750, 1080, 1366, 1920], formats: [AVIF, AUTO, WEBP])
      }
    }
    mobileImage: file(relativePath: { eq: "hero/opcja-natura_hero_mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90, breakpoints: [400, 500, 600], formats: [AVIF, AUTO, WEBP])
      }
    }
  }
`;

const HeroStatic = () => {
  const { desktopImage, mobileImage } = useStaticQuery<{ desktopImage: ImageDataLike; mobileImage: ImageDataLike }>(query);

  const images = withArtDirection(getImage(mobileImage) as IGatsbyImageData, [
    {
      media: "(min-width: 600px)",
      image: getImage(desktopImage) as IGatsbyImageData,
    },
  ]);

  return (
    <Wrapper>
      <StyledBanner loading="eager" image={images} $height="90vh" $tag="section" alt="" />
      <BannerText>
        <h5>Bo z naturą Ci do twarzy</h5>
        <h1>Naturalnie skuteczne kosmetyki</h1>
        <div className="link_wrapper">
          <ButtonGlobal testid={"shop-button"} color="#fff" text="odkryj produkty" padding="1.5em 3em" link={"/sklep"} />
        </div>
      </BannerText>
    </Wrapper>
  );
};

export default HeroStatic;
