import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { TitleHomepage, ProductTitle } from "../../UI/index";
import Price from "../../shop/product/Price";
import ProductMdxWrapper from "../../../styles/wrappers/ProductTextStyling";
import BuyButton from "../../shop/buyButton/BuyButton";
import { theme } from "../../../styles/theme/theme";
import { ProductProps, ValidateSubType } from "../../../utils/types/graphQLTypes";
import Link from "../../UI/elements/Link";
import Markdown from "markdown-to-jsx";

const Wrapper = styled.section`
  margin: ${theme.spaces.margin.vertical} 0;
  display: grid;
  grid-template-rows: auto;
  grid-template-areas:
    "title"
    "image"
    "text";

  @media (min-width: 680px) {
    margin: ${theme.spaces.margin.vertical_big} 0;
  }

  @media (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "title title"
      "image text";
  }

  @media (min-width: 1300px) {
    grid-template-columns: 1fr 1fr;
    margin-left: 10em;
    margin-right: 10em;
  }
`;

const Image = styled(GatsbyImage)`
  grid-area: image;

  @media (min-width: 1700px) {
    max-width: 90%;
    height: auto;
    margin-left: 20%;
  }
`;

const Text = styled.article`
  grid-area: text;
  padding-right: ${theme.spaces.padding.base};
  padding-left: ${theme.spaces.padding.small};
  margin: ${theme.spaces.margin.large} 0;
  font-weight: ${theme.fonts.fontWeight.light};
  font-size: ${theme.fonts.fontSize.xl};
  white-space: pre-wrap;

  @media (min-width: 800px) {
    margin: 0;
  }
  @media (min-width: 1200px) {
    padding-left: 4em;
  }

  .product-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: ${theme.spaces.margin.large} 0;
    font-weight: ${theme.fonts.fontWeight.light};
    font-size: ${theme.fonts.fontSize.xl};
    white-space: pre-wrap;

    @media (min-width: 980px) {
      margin-right: ${theme.spaces.margin.xxl};
    }
  }

  .product-description {
    color: rgb(11, 10, 10);
    text-align: justify;
    text-justify: inter-word;
    white-space: pre-line;
    line-height: 1.8;
    @media (min-width: 1500px) {
      padding-right: 20%;
    }
  }

  .product-inci {
    margin: ${theme.spaces.margin.xxl} 0;
  }

  .product-inci--text {
    p:first-of-type {
      display: block !important;
      text-align: justify;
      text-justify: inter-word;
      white-space: pre-line;
      line-height: 1.8;

      font-weight: ${theme.fonts.fontWeight.thin};

      @media (min-width: 1500px) {
        padding-right: 20%;
      }
    }
    p:not(:first-of-type) {
      display: none;
    }

    hr {
      display: none;
    }
  }

  .product-buy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1em;
  }

  .product-link-container {
    margin-top: ${theme.spaces.margin.big};
  }
`;

const query = graphql`
  {
    storeProduct(active: { eq: true }, mainPage: { eq: true }) {
      images {
        fileLocal {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
      }
      ...StoreProductItem
      description
    }
  }
`;

type ProductPropsLocal = Pick<ProductProps, "images" | "description" | "name_span" | "name" | "prices" | "custom_attributes" | "product_id" | "slug">;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TYPE_GUARD: ValidateSubType<ProductPropsLocal, ProductProps> = true;

interface DataProps {
  storeProduct: ProductPropsLocal;
}

const Index = () => {
  const { storeProduct: product } = useStaticQuery<DataProps>(query);
  if (!product) {
    console.error("No featured product found");
    return <></>;
  }

  const lastImage = product.images.length - 1;
  const image = getImage(product.images[lastImage].fileLocal);
  if (!image) {
    throw new Error(`No image found for product name ${product.name}`);
  }
  const price = product.prices["PLN"];

  return (
    <Wrapper>
      <TitleHomepage title="kosmetyk miesiąca" span={product.name_span} />
      <Image image={image} alt={product.name} />

      <Text>
        <ProductTitle>{product.name}</ProductTitle>
        <div className="product-info">
          <Price
            price={price.sale && price.previous_unit_price ? price.previous_unit_price : price.unit_price}
            sale={price.sale}
            sale_price={price.sale ? price.unit_price : undefined}
            capacity={product.custom_attributes.capacity}
            isProductPage={true}
            productName={product.name}
            margin="1em 0"
          />
        </div>

        <ProductMdxWrapper className="product-description">
          <Markdown>{product.description}</Markdown>
        </ProductMdxWrapper>

        <div className="product-inci">
          <span>SKŁAD:</span>
          <div className="product-inci--text">
            <Markdown>{product.custom_attributes.sklad}</Markdown>
          </div>
        </div>
        <div className="product-buy">
          <BuyButton productId={product.product_id} images={product.images} />
          <span className="product-link-container">
            <Link to={`/sklep/${product.slug}`} className="border__out">
              Zobacz szczegóły produktu
            </Link>
          </span>
        </div>
      </Text>
    </Wrapper>
  );
};

export default Index;
