import React from "react";
import styled from "styled-components";
import ShopCategoriesMenu from "../menu/ShopCategoriesMenu";
import { theme } from "../../../styles/theme/theme";
import Link from "../../UI/elements/Link";

const Wrapper = styled.section`
  padding-left: ${theme.spaces.padding.base};
  position: sticky;
  top: 10em;
  h5 {
    font-weight: ${theme.fonts.fontWeight.normal};
    text-transform: uppercase;
    letter-spacing: 3px;
  }
`;

const CategoriesLinks = styled.ul`
  padding-left: ${theme.spaces.padding.base};
  font-weight: ${theme.fonts.fontWeight.thin};
  line-height: 4em;

  li {
    color: ${theme.colors.secondary};
    letter-spacing: 1px;
    text-transform: lowercase;
    :first-letter {
      text-transform: uppercase;
    }
  }

  .activeMobile {
    font-weight: ${theme.fonts.fontWeight.normal};
    color: ${theme.colors.black};
  }
`;

const CategoryPageMenu = () => {
  return (
    <Wrapper>
      <Link
        to="/sklep"
        /* onClick={() =>
        dataLayerLinkPush("view_items_list", "shop", "main-shop-page")
      } */
      >
        <h5>Sklep</h5>
      </Link>

      <CategoriesLinks data-testid={"shop-categories"}>
        <ShopCategoriesMenu />
      </CategoriesLinks>
    </Wrapper>
  );
};

export default CategoryPageMenu;
