import { DataLayerECommerce, DataLayerEvent, getWindow } from "../types/window";
import { Cart, Product } from "../types/dto";
import { Currency } from "@on/model-store";

export type ViewItemListPush = Pick<Product, "name" | "baselinker_id"> & {
  prices: Record<Currency, { active: boolean; currency: string; unit_price: number; previous_unit_price?: number; sale: boolean; taxRate: number }>;
};

interface SingleItem {
  name: string;
  email?: string;
  baselinker_id: string;
  prices: Record<
    Currency,
    {
      active: boolean;
      currency: string;
      unit_price: number;
      previous_unit_price?: number;
      sale: boolean;
      taxRate: number; // 0.23
    }
  >;
}

export const singleItemPush = (item: SingleItem, event: DataLayerEvent) => {
  const price = item.prices["PLN"];
  const discountValue = price.previous_unit_price && price.sale ? (price.previous_unit_price - price.unit_price).toFixed(2) : price.unit_price.toFixed(2);
  const itemPrice = price.previous_unit_price && price.sale ? price.previous_unit_price : price.unit_price;

  const windowAccess = getWindow();

  windowAccess.dataLayer = windowAccess.dataLayer || [];
  windowAccess.dataLayer.push({ ecommerce: null });
  windowAccess.dataLayer.push({
    event: `${event}`,
    user: item.email,
    currency: "PLN",
    value: Number((itemPrice / 1.23).toFixed(2)),
    ecommerce: {
      items: [
        {
          item_name: item.name,
          item_id: item.baselinker_id,
          currency: "PLN",
          price: Number(itemPrice.toFixed(2)),
          discount: Number(discountValue),
          quantity: 1,
        },
      ],
    },
  });
};

export const viewItemListPush = (listItems: ViewItemListPush[], event: DataLayerEvent, category: string) => {
  const items: DataLayerECommerce["items"] = listItems.map((i) => ({
    item_name: i.name,
    item_id: i.baselinker_id,
    currency: "PLN",
    price: Number(i.prices["PLN"].unit_price.toFixed(2)),
    discount: i.prices["PLN"].sale && i.prices["PLN"].previous_unit_price ? Number((i.prices["PLN"].previous_unit_price - i.prices["PLN"].unit_price).toFixed(2)) : 0,
    quantity: 1,
  }));
  const windowAccess = getWindow();
  windowAccess.dataLayer = windowAccess.dataLayer || [];
  windowAccess.dataLayer.push({ ecommerce: null });
  windowAccess.dataLayer.push({
    event: `${event}`,
    currency: "PLN",
    item_list_name: `${category}`,
    ecommerce: {
      items,
    },
  });
};

export const cartItemsPush = (state: Cart, event: DataLayerEvent) => {
  const email = state?.email || undefined;
  const purchaseGrossValue = state.grand_total - state.shipment_total; //TODO
  const items: DataLayerECommerce["items"] = state.items.map((i) => ({
    item_name: i.product.name,
    item_id: i.product.baselinker_id,
    price: i.product.unit_price,
    discount: i.product?.sale && i.product.previous_unit_price ? Number((i.product.previous_unit_price - i.product.unit_price).toFixed(2)) : 0,
    quantity: i.quantity,
    currency: "PLN",
  }));
  const windowAccess = getWindow();
  windowAccess.dataLayer = windowAccess.dataLayer || [];
  windowAccess.dataLayer.push({ ecommerce: null });
  const totalItems = items.reduce((acc, item) => acc + item.quantity, 0);
  windowAccess.dataLayer.push({
    event: `${event}`,
    value: Number((purchaseGrossValue / 1.23).toFixed(2)),
    currency: "PLN",
    transaction_id: state?.cartId || undefined,
    user: email,
    tax: state?.tax_total.toFixed(2),
    coupon: state?.coupon_code || undefined,
    num_items: totalItems,
    ecommerce: {
      items,
    },
  });
};

export const cartSingleItemPush = (cart: Cart, event: DataLayerEvent, productId: string) => {
  const item = cart.items.find((item) => item.product.product_id === productId);
  if (!item) {
    console.error(`No item found in cart with productId ${productId}, cart: ${JSON.stringify(cart)}`);
    return;
  }
  const email = cart?.email || undefined;
  const windowAccess = getWindow();
  windowAccess.dataLayer = windowAccess.dataLayer || [];
  windowAccess.dataLayer.push({ ecommerce: null });
  windowAccess.dataLayer.push({
    event: `${event}`,
    value: Number((item.product.unit_price / 1.23).toFixed(2)),
    currency: "PLN",
    user: email,
    ecommerce: {
      items: [
        {
          currency: "PLN",
          item_name: item.product.name,
          item_id: item.product.baselinker_id,
          price: Number(item.product.unit_price.toFixed(2)),
          discount: item.product?.previous_unit_price ? Number((item.product.previous_unit_price - item.product.unit_price).toFixed(2)) : 0,
          quantity: 1,
        },
      ],
    },
  });
};
